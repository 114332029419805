<template>
  <div id="mian">
    <div>
      <div class="content">
        <div class="desc">
          <h5 class="jsxx">订单信息</h5>
          <div>
            <el-button v-if="false" class="reEmailBt" type="primary">重发邮件</el-button>
          </div>
        </div>
        <ul class="listBox">
          <li><span>商户订单号</span><i class="hidden1">{{detail.mainOrderNo?detail.mainOrderNo:''}}</i></li>
          <li><span>商户子订单号</span><i class="hidden1">{{detail.subOrderNo?detail.subOrderNo:''}}</i></li>
					<li><span>三方订单号</span><i class="hidden1">{{detail.thirdOrderNo}}</i></li>
          <li><span>三方子订单号</span><i class="hidden1">{{detail.thirdTrxNo?detail.thirdTrxNo:''}}</i></li>
          <li><span>商户名称</span><i class="hidden1">
            <el-tooltip class="item" effect="light" :content="detail.mchName" placement="top">
              <b>{{detail.mchName}}</b>
            </el-tooltip>
          </i></li>
          <li><span>商户编号</span><i class="hidden1">{{detail.mchId?detail.mchId:''}}</i></li>
          <li><span>代理商名称</span><i class="hidden1">
            <el-tooltip class="item" effect="light" :content="detail.agentName" placement="top">
              <b>{{detail.agentName}}</b>
            </el-tooltip>
          </i></li>
          <li><span>服务商名称</span><i class="hidden1">{{detail.servicerName?detail.servicerName:''}}</i></li>
          <li><span>所属机构</span><i class="hidden1">{{detail.orgName?detail.orgName:''}}</i></li>
          <li><span>渠道商户号</span><i class="hidden1">{{detail.channelMchNo?detail.channelMchNo:''}}</i></li>
          <li><span>创建时间</span><i class="hidden1">{{detail.createTime?detail.createTime:''}}</i></li>
          <li><span>完成时间</span><i class="hidden1">{{detail.tradeTime?detail.tradeTime:''}}</i></li>
          <li><span>海关名称</span><i class="hidden1">{{customsCode[detail.customsCode]?customsCode[detail.customsCode]:detail.customsCode}}</i></li>
          <li><span>申报类型</span><i class="hidden1">{{functionCode[detail.functionCode]}}</i></li>
          <li><span>检验检疫机构名称</span><i class="hidden1">{{ciqOrgCode[detail.ciqOrgCode]?ciqOrgCode[detail.ciqOrgCode]:detail.ciqOrgCode}}</i></li>
          <li><span>电商平台互联网域名</span><i class="hidden1">
            <el-tooltip class="item" effect="light" :content="detail.domainName" placement="top">
              <b>{{detail.domainName}}</b>
            </el-tooltip>
          </i></li>
          <li><span>电商平台代码</span><i class="hidden1">{{detail.tmallCode?detail.tmallCode:''}}</i></li>
          <li><span>电商平台名称</span><i class="hidden1">{{detail.tmallName?detail.tmallName:''}}</i></li>
          <li><span>订单金额</span><i class="hidden1">{{detail.amount | formatMoney}}</i></li>
          <li><span>订单名称</span><i class="hidden1">
            <el-tooltip class="item" effect="light" :content="detail.productName" placement="top">
              <b>{{detail.productName}}</b>
            </el-tooltip>
          </i></li>
          <li><span>姓名</span><i class="hidden1">{{detail.payerName?detail.payerName:''}}</i></li>
          <li><span>证件类型</span><i class="hidden1">{{detail.payerIdType==1?'身份证':''}}</i></li>
          <li><span>证件号码</span><i class="hidden1">{{detail.payerIdNo?detail.payerIdNo:''}}</i></li>
          <li><span>手机号码</span><i class="hidden1">{{detail.payerTel?detail.payerTel:''}}</i></li>
          <li><span>关税</span><i class="hidden1">{{detail.payTaxAmount?detail.payTaxAmount:'' | formatMoney}}</i></li>
          <li><span>运费</span><i class="hidden1">{{detail.freight?detail.freight:'' | formatMoney}}</i></li>
          <li><span>报关类型</span><i class="hidden1">{{orderType[detail.orderType]}}</i></li>
          <li><span>申报状态</span><i class="hidden1">{{status[detail.status]}}</i></li>
        </ul>
      </div>
    </div>
  </div>
</template>
<script>
	import {
		getCustomsordersDetail
	} from '@/api/trading/cuetoms.js'
  export default {
    data() {
      return {
				detail:'',
        crumbs: "交易详情",
				orderType:{
					0:'新增',
					1:'修改'
				},
				status:{
					100:'申报成功',
					101:'申报失败',
					104:'申报处理中'
				},
				functionCode:{
					CUS:'单向海关申报',
					CIQ:'单向国检申报',
					BOTH:'海关和国检同时申报'
				},
        customsCode:{
          5141 :'广州机场',
          5145 :'广州邮办',
          5208 :'埔开发区(黄埔)',
          52081 :'黄埔状元谷智检口岸',
          5190 :'韶关海关',
          8012 :'重庆保税',
          5300 :'深圳海关',
          5700 :'珠海海关(拱北)',
          5100 :'广州海关',
          5165 :'南沙海关',
          900 :'大连海关',
          1000 :'海关总署',
          1531 :'长春海关',
          3100 :'宁波海关',
          2900 :'杭州海关'
        },
        ciqOrgCode:{
          440000 :'广东局本部',
          440009 :'广东局金伯利办公室',
          440020 :'广东局凤岗办事处',
          440030 :'广东局黄埔老港办事处',
          440070 :'广东局广州空港综合保税区办事处',
          440100 :'广州局本部',
          440110 :'广州局罗岗办事处',
          440130 :'广州局新风港办事处',
          440400 :'顺德局本部',
          440430 :'顺德局陈村办事处',
          440600 :'佛山局本部',
          440650 :'佛山局快件监督办事处',
          441350 :'惠州局车检场办事处',
          441900 :'东莞局本部',
          441950 :'东莞局沙田办事处',
          442000 :'中山局本部',
          442050 :'中山局神湾办事处',
          442100 :'黄埔局本部',
          442120 :'黄埔局开发区办事处',
          442300 :'广州机场局本部',
          442400 :'番禺局本部',
          443000 :'高明局本部',
          443400 :'南沙局本部',
          443420 :'南沙局龙穴岛办事处',
          442302 :'广州机场快件转运中心办事处',
          441200 :'肇庆局本部'
        }
      }
    },
		created() {
			this.getDetail()
		},
    methods: {
			getDetail(){
				getCustomsordersDetail(this.$route.query.id).then(res=>{
					if(res){
						this.detail = res.resultData
					}
				})
			},
      //菜单切换
      navClick(data) {
        this.crumbs = data
      },
    }
  }
</script>
<style scoped>
@import "../../../../assets/css/desc.css";
@import "../../../../assets/css/detail.css";
.tab1{
  min-height: auto !important;
}
</style>
