import axios from '@/utils/request'

// 跨境-海关单列表
export function getCustomsorders(params) {
  // params.loading = true
  return axios({
    url: `/manager/trade/customsorders`,
    method: 'get',
		params
  })
}
// 跨境-海关单详情
export function getCustomsordersDetail(customsOrderId) {
  return axios({
    url: `/manager/trade/customsorders/${customsOrderId}`,
    method: 'get'
  })
}
// 跨境-海关单搜索
export function getCustomsordersSearch(params) {
  // params.loading = true
  return axios({
    url: `/manager/trade/customsorders/search`,
    method: 'get',
		params
  })
}
// 跨境-订单同步
export function getCustomsordersSync(customsOrderId) {
  return axios({
    url: `/manager/trade/customsorders/${customsOrderId}/sync`,
    method: 'get'
  })
}
// 跨境-订单导出
export function exportCustomsorders(params) {
  return axios({
    responseType:'blob',
    url: `/manager/trade/customsorders/export`,
    method: 'post',
    params
  })
}